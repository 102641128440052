<template>
    <div class="page-wrapper">
        <PageSection :loading="PREPAY_LOADING">
            <div v-if="PREPAY_DETAILS" class="col-md-8 offset-md-2">
                <h1 class="pb-3 text-center">{{ t('prepay_details.title') }}</h1>

                <!-- <h3 class="p-3 text-center">{{ t('prepay_details.sub_title_in') }}</h3> -->
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>{{ t('prepay_details.table_in_reference') }}</th>
                            <th>{{ t('prepay_details.table_in_purchase_date') }}</th>
                            <th>{{ t('prepay_details.table_in_minute_purchased') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="detailsIn in PREPAY_DETAILS.prepayDetailsIn" :key="detailsIn.id">
                            <td>{{ detailsIn.reference }}</td>
                            <td>{{ new Date(detailsIn.purchaseDate).toLocaleString() }}</td>
                            <td>{{ detailsIn.minutePurchased }}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- <h3 class="p-3 text-center">{{ t('prepay_details.sub_title_out') }}</h3>
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>{{ t('prepay_details.table_out_called_to') }}</th>
                            <th>{{ t('prepay_details.table_out_call_started') }}</th>
                            <th>{{ t('prepay_details.table_out_call_ended') }}</th>
                            <th>{{ t('prepay_details.table_out_duration') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="detailsOut in PREPAY_DETAILS.prepayDetailsOut" :key="detailsOut.id">
                            <td>{{ detailsOut.calledTo }}</td>
                            <td>{{ new Date(detailsOut.callStarted).toLocaleString() }}</td>
                            <td>{{ new Date(detailsOut.callEnded).toLocaleString() }}</td>
                            <td>{{ detailsOut.durationMinute }}</td>
                        </tr>
                    </tbody>
                </table> -->
            </div>

            <router-link :to="{ name: 'Route_PrepayOverview' }">
                {{ t('prepay_details.checkout_link') }}
            </router-link>
        </PageSection>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import PageSection from '../../components/PageSection.vue';

    const store = useStore();
    const { t } = useI18n();

    store.dispatch('pagePrepayDetails/LOAD_PREPAY_DETAILS');

    const PREPAY_LOADING = computed(() => store.getters['pagePrepayDetails/PREPAY_LOADING']);
    const PREPAY_DETAILS = computed(() => store.getters['pagePrepayDetails/PREPAY_DETAILS']);
</script>
